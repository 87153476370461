<script setup>
</script>

<template>
  <div class="footer">
    <div class="zhong">
      <span class="footer-text1" style="position:absolute;left: 183px;top: 103px">认识我们</span>
      <span class="footer-text2" style="position:absolute;left: 183px;top: 166px">财金科技</span>
      <span class="footer-text2" style="position:absolute;left: 183px;top: 229px">企业融资</span>
      <span class="footer-text2" style="position:absolute;left: 183px;top: 293px">税务授权</span>
      <span class="footer-text2" style="position:absolute;left: 183px;top: 355px">我要赚钱</span>
      <span class="footer-text1" style="position:absolute;left: 385px;top: 103px">联系我们</span>
      <span class="footer-text2" style="position:absolute;left: 385px;top: 166px">财金科技</span>
      <span class="footer-text2" style="position:absolute;left: 385px;top: 229px">企业融资</span>
      <span class="footer-text2" style="position:absolute;left: 385px;top: 293px">税务授权</span>
      <span class="footer-text2" style="position:absolute;left: 385px;top: 355px">我要赚钱</span>
      <img src="../../pic/首页/图像%203@2x.png" alt="" style="width: 148px;height: 148px;position:absolute;left: 1356px;top: 220px">
      <img src="../../pic/首页/图像%204@2x.png" alt="" style="width: 148px;height: 148px;position:absolute;left: 1542px;top: 220px">
    </div>
  </div>
</template>

<style scoped>
.footer {
  position: relative;
  height: 550px;
  width: 100%;
  background-color: #252931;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; /* 水平居中整个a容器 */
}
.zhong{
  position: relative;
  width: 1800px;
  height: 550px;
}
.footer-text1 {
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 21px;
  color: #FFFFFF;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.footer-text2 {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #8E8F93;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
</style>
