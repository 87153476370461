<script setup>
import { ref,reactive, computed} from 'vue';

const menuItems = ref([
  { label: '融资管理', icon: require('@/pic/融资方案/路径 68@2x.png') },
  { label: '企业智选', icon: require('@/pic/融资方案/路径 69@2x.png') },
  { label: '账号管理', icon: require('@/pic/融资方案/路径 70@2x.png') },
  { label: '消息中心', icon: require('@/pic/融资方案/路径 71@2x.png') },
]);

const selectedButton = ref(null);

const selectButton = (index) => {
  selectedButton.value = index;
};

const titles = ref(['产品名称', '客户信息', '融资进度', '授权额度(元)', '放款额度(元)', '年化', '还款方式']);

const state = reactive({
  date: new Date(),
});

const daysOfWeek = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];

const formattedDate = computed(() => {
  const year = state.date.getFullYear();
  const month = ('0' + (state.date.getMonth() + 1)).slice(-2); // 获取月份并补零
  const day = ('0' + state.date.getDate()).slice(-2); // 获取日期并补零
  const dayOfWeek = daysOfWeek[state.date.getDay()]; // 获取星期几

  return `${year}年${month}月${day}日 ${dayOfWeek}`;
});
</script>

<template>
  <div class="container">
    <div class="sidebar">
      <ul>
        <li v-for="(item, index) in menuItems" :key="index">
          <button
              :class="{ active: selectedButton === index }"
              @click="selectButton(index)"
          >
            <img :src="item.icon" alt="" class="button-icon" />
            {{ item.label }}
          </button>
        </li>
      </ul>
    </div>
    <div class="center">
      <div class="content">
        <div class="welcome">
          <div class="welcome-text">您好，188****6235欢迎你！</div>
          <div class="time-text">{{ formattedDate }}</div>
        </div>
        <div class="search">
          <input type="text" placeholder="    客户类别">
          <input type="text" placeholder="    搜索企业名称">
          <input type="text" placeholder="    搜索客户手机号">
          <button type="button">提交</button>
        </div>
        <img src="@/pic/融资方案/直线%2064@2x.png" class="partition">
        <div class="list">
          <ul class="horizontal-list">
            <li v-for="(title, index) in titles" :key="index" :class="'spacing-' + index">
              {{ title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  width: 1440px;
  height: 1285px;
}

.sidebar {
  width: 263px;
  height: 100vh;
  background-color: #F8F9FB;
  position: absolute;
  top: 141px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  margin: 15px 0;
}

.sidebar li button {
  width: 234px;
  height: 53px;
  margin-left: 15px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #F8F9FB;
  display: flex;
  align-items: center;
}

.button-icon {
  width: 19px;
  height: 21.5px;
  margin-right: 8px;
  margin-left: 29px;
}

.sidebar li button.active {
  background-color: #FFFFFF;
  color: #292929;
  border-radius: 10px;
}

.center {
  position: absolute;
  left: 234px;
  top: 141px;
  height: 1285px;
  width: calc(100% - 263px);
  display: flex;
  justify-content: center;
}

.content {
}

.welcome {
  background-image: url('@/pic/融资方案/蒙版组 2@2x.png');
  height: 183px;
  width: 1215px;
  margin-top: 18px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.welcome-text{
  width: 445px;
  height: auto;
  padding-left: 54px;
  padding-top: 40.8px;
  font-size: 33.6px;
  color: #FFFFFF;
}
.time-text{
  padding-left: 64px;
  padding-top: 6px;
  width: 213.6px;
  font-size: 19px;
  color: #FFFFFF;
}
.search {
  height: 34px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.search input {
  width: 218px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.search button {
  width: 60px;
  height: 34px;
  background-color: #007BFF;
  color: white;
  font-size: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search button:hover {
  background-color: #0056b3;
}

.partition {
  width: 1215px;
  margin-top: 19px;
}

.list {
  width: 100%;
  margin-top: 17px;
}

.horizontal-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* 使用 flex-start 使间距生效 */
}

.horizontal-list li {
  border-radius: 4px;
}

.spacing-0 {
  margin-right: calc(138px * 1.2); /* 165.6px */
}

.spacing-1 {
  margin-right: calc(170px * 1.2); /* 204px */
}

.spacing-2 {
  margin-right: calc(66px * 1.2); /* 79.2px */
}

.spacing-3 {
  margin-right: calc(54px * 1.2); /* 64.8px */
}

.spacing-4 {
  margin-right: calc(65px * 1.2); /* 78px */
}

.spacing-5 {
  margin-right: calc(77px * 1.2); /* 92.4px */
}

.spacing-6 {
  margin-right: 0; /* 保持不变 */
}


</style>