import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://jianxj.com:666', // 配置后端地址
    timeout: 1000,
});

instance.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么
        // 例如添加 token
        const token = localStorage.getItem('token');
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    error => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => {
        // 对响应数据做点什么
        return response;
    },
    error => {
        // 对响应错误做点什么
        return Promise.reject(error);
    }
);

export default instance;
