<template>
  <div class="home-container">
    <NavBar />

    <div class="main">
      <homefirst/>
      <hometwo/>
      <homethree/>
      <homefour/>
      <homefive/>
      <homesix/>
    </div>

    <FooterBar />
  </div>
</template>

<script setup>
import {onMounted, defineComponent} from 'vue';
import NavBar from "@/components/通用/NavBar.vue";
import homefirst from '@/components/首页/home-first.vue';
import hometwo from '@/components/首页/home-two.vue';
import homethree from '@/components/首页/home-three.vue';
import homefour from '@/components/首页/home-four.vue';
import homefive from '@/components/首页/home-five.vue';
import homesix from '@/components/首页/home-six.vue';
import FooterBar from "@/components/通用/FooterBar.vue";
defineComponent({
  name: 'HomeComponent'
});

onMounted(() => {
  console.log("Window inner width:", window.innerWidth);
  console.log("Document client width:", document.documentElement.clientWidth);
});
</script>

<style scoped>

.home-container{
    display: flex;
    flex-direction: column;
  }

  .main {
    flex-shrink: 0; /* 不允许 main 压缩 */
    flex-grow: 1;
  }
</style>
