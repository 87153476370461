<template>
  <!--   第五层 -->
  <div class="home-five">
    <img src="../../pic/首页/蓝三角.png" alt="" style="width: 3.53%; height: 6.34%; position: absolute; top: 9.81%; left: 9.78%;">
    <span class="text-big" style="position: absolute; top: 13.03%; left: 12.34%;">合作伙伴</span>
    <span class="text-small" style="position: absolute; top: 13.82%; left: 22.01%;">持牌银行、金融机构、安全放心</span>
    <span class="five-big" style="position: absolute; top: 29.14%; left: 24.79%;">65万+</span>
    <span class="text-big" style="font-size: 31px; position: absolute; top: 29.14%; left: 32.91%;">企业客户</span>
    <span class="five-big" style="position: absolute; top: 29.14%; left: 44.28%;">30+</span>
    <span class="text-big" style="font-size: 31px; position: absolute; top: 29.14%; left: 49.96%;">公司群体</span>
    <span class="five-big" style="position: absolute; top: 29.14%; left: 61.48%;">300+</span>
    <span class="text-big" style="font-size: 31px; position: absolute; top: 29.14%; left: 68.61%;">覆盖城市</span>

    <!-- 调整后的图片，位于文字下方并居中 -->
    <img
        src="../../pic/首页/bank.png"
        alt=""
        style="
        position: absolute;
        top: 40%; /* 根据需要调整此值 */
        left: 50%;
        transform: translateX(-50%);
        width: 100% /* 根据需要设置宽度，例如 20% */;
        height: auto/* 根据需要设置高度，例如 auto */;
      "
    >
  </div>
</template>

<script setup>
</script>

<style scoped>
.home-five{
  width: 100%;
  height: 836px;
  background-image: url("@/pic/首页/组 80@2x.png");
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  left: 0;
  padding: 0; /* 确保没有内边距 */
  margin: 0;  /* 确保没有外边距 */
  background-size: 100% 150%; /* 具体长宽 */
}
.five-big{
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 47px;
  color: #4380F1;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.text-big{
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 36px;
  color: #292929;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.text-small{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 21px;
  color: #747474;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
</style>
