<template>
  <!-- 第四层 -->
  <div class="home-four" ref="homeFour">
    <img src="../../pic/首页/蓝三角.png" alt="" class="blue-triangle">
    <span class="text-big" style="position: absolute; top: 13.63%; left: 12.34%;">助力中小微企业融资</span>
    <span class="text-small" style="position: absolute; top: 14.44%; left: 32.16%;">高效、精准、快捷</span>
    <img src="../../pic/首页/矩形%2029@2x.png" alt="" class="rectangle" style="position: absolute; top: 21.75%; left: 9.78%; width: 0.48%; height: 3.63%;">
    <span class="text-big" style="position: absolute; top: 23.44%; left: 10.79%; font-size: 31px;">实时融资动态</span>
    <img src="../../pic/首页/矩形%2030@2x.png" alt="" class="rectangle" style="position: absolute; top: 21.75%; left: 55.36%; width: 0.48%; height: 3.63%;">
    <span class="text-big" style="position: absolute; top: 23.44%; left: 56.32%; font-size: 31px;">累计服务</span>
    <img src="../../pic/首页/50842302b4c5d7e6ecb27eed1dfa3425be4a12383fb4f-UsFKdW@2x.png" alt="" class="service-image" style="position: absolute; top: 35.75%; left: 60.69%; width: 23.35%; height: 53.00%;">

    <span class="text-four" style="position: absolute; top: 37.44%; left: 68.49%;">今日对接服务数</span>
    <span class="text-four" style="position: absolute; top: 49.31%; left: 57.79%;">入驻金融机构</span>
    <span class="text-four" style="position: absolute; top: 49.31%; left: 79.92%;">融资产品丰富</span>
    <span class="text-four" style="position: absolute; top: 75.81%; left: 58.82%;">累计服务客户</span>
    <span class="text-four" style="position: absolute; top: 75.81%; left: 80.42%;">累计撮合金额</span>
    <!-- 使用 v-for 动态生成动画数字 -->
    <span
        v-for="(item, index) in countItems"
        :key="index"
        class="text-big count-up"
        :data-target="item.target"
        :data-suffix="item.suffix"
        style="position: absolute;"
        :style="{ top: item.top, left: item.left }"
    >
      0{{ item.suffix }}
    </span>

    <!-- 新增的自动滚动表格 -->
    <div class="scrolling-table">
      <div class="scrolling-content">
        <!-- 使用 v-for 动态生成滚动项 -->
        <div class="scroll-item" v-for="(scrollItem, idx) in scrollItems" :key="idx">
          <span class="message">{{ scrollItem.message }}</span>
          <span class="location">{{ scrollItem.location }}</span>
          <span class="time">{{ scrollItem.time }}</span>
        </div>
        <!-- 重复内容以实现无缝滚动 -->
        <div class="scroll-item" v-for="(scrollItem, idx) in scrollItems" :key="'dup-' + idx">
          <span class="message">{{ scrollItem.message }}</span>
          <span class="location">{{ scrollItem.location }}</span>
          <span class="time">{{ scrollItem.time }}</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { CountUp } from 'countup.js'

// 定义 homeFour 的 ref
const homeFour = ref(null)

// 定义要动画的数字项
const countItems = ref([
  { target: 740, suffix: '', top: '32.38%', left: '70.64%' },
  { target: 50, suffix: '+', top: '44.25%', left: '59.19%' },
  { target: 100, suffix: '+', top: '44.25%', left: '80.82%' },
  { target: 970, suffix: '+', top: '70.75%', left: '59.68%' },
  { target: 1000, suffix: '亿＋', top: '70.75%', left: '79.92%' },
])

// 定义滚动表格的项目
const scrollItems = ref([
  { message: '重****公司正在申请新网好企e贷产品', location: '重庆市', time: '1分钟前' },
  { message: '中****公司正在申请新网好企e贷产品', location: '上海市', time: '5分钟前' },
  { message: '发****公司正在申请新网好企e贷产品', location: '北京市', time: '10分钟前' },
  { message: '重****公司正在申请新网好企e贷产品', location: '重庆市', time: '1分钟前' },
  { message: '中****公司正在申请新网好企e贷产品', location: '上海市', time: '5分钟前' },
  { message: '发****公司正在申请新网好企e贷产品', location: '北京市', time: '10分钟前' },
  { message: '重****公司正在申请新网好企e贷产品', location: '重庆市', time: '1分钟前' },
  { message: '中****公司正在申请新网好企e贷产品', location: '上海市', time: '5分钟前' },
  { message: '发****公司正在申请新网好企e贷产品', location: '北京市', time: '10分钟前' },
  // 你可以根据需要添加更多项
])

// 冷却标志和时间
let isAnimating = false
const animationCooldown = 1000 // 1秒

const startCountUp = () => {
  if (isAnimating) return
  isAnimating = true

  countItems.value.forEach((item, index) => {
    const el = homeFour.value.querySelectorAll('.count-up')[index]
    if (!el) return

    const target = item.target
    const suffix = item.suffix

    // 重置显示内容为0 + suffix
    el.textContent = `0${suffix}`

    // 创建新的 CountUp 实例
    const countUp = new CountUp(el, target, {
      duration: 2, // 动画持续时间（秒）
      separator: ',', // 数字分隔符
      suffix: suffix,
      startVal: 0 // 确保从0开始
    })

    if (!countUp.error) {
      countUp.start()
    } else {
      console.error(countUp.error)
    }
  })

  // 设置冷却时间
  setTimeout(() => {
    isAnimating = false
  }, animationCooldown)
}

onMounted(() => {
  if (!homeFour.value) return

  // 初始化 IntersectionObserver
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        startCountUp()
      }
    })
  }, {
    threshold: 0.5 // 当 50% 出现在视口时触发
  })

  // 开始观察
  observer.observe(homeFour.value)

  // 在卸载时断开观察者
  onBeforeUnmount(() => {
    observer.disconnect()
  })
})
</script>

<style scoped>
.home-four {
  width: 100%;
  height: 800px;
  background-color: rgba(244, 248, 255, 1);
  position: relative;
  left: 0;
}

.text-big {
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 31px;
  color: #292929;
  line-height: 1;
  text-align: left;
  text-transform: none;
}

.text-small {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 21px;
  color: #747474;
  line-height: 1;
  text-align: left;
  text-transform: none;
}

.text-four {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 21px;
  color: #666666;
  line-height: 1;
  text-align: left;
  text-transform: none;
}

.blue-triangle {
  width: 3.53%;
  height: 6.63%;
  position: absolute;
  top: 10.13%;
  left: 9.78%;
}

.rectangle {
  /* 统一矩形样式 */
}

.service-image {
  /* 自定义服务图片样式 */
}

.count-up {
  /* 为动画数字添加额外样式 */
  font-weight: 600;
  color: #4380F1;
}

/* 新增的滚动表格样式 */
.scrolling-table {
  position: absolute;
  top: 35%; /* 调整为适当的位置 */
  left: 7%; /* 定位到左侧 */
  width: 35%; /* 设置适当的宽度以占据左侧 */
  height: 50%; /* 设置固定高度，超出部分隐藏 */
  overflow: hidden;
  background-color: transparent; /* 去除背景颜色 */
  /* 移除边框、圆角和阴影 */
  border: none;
  box-shadow: none;
  /* 可选：调整文字对齐和字体大小 */
  font-size: 16px;
}

.scrolling-content {
  display: flex;
  flex-direction: column;
  animation: scroll-up 10s linear infinite;
}

.scroll-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  white-space: nowrap;
  /* 去除分隔线 */
  border-bottom: none;
}

.scroll-item:not(:last-child) {
  border-bottom: 1px solid #eee; /* 可选：仅在非最后一项添加分隔线 */
}

.message {
  flex: 3;
  font-weight: 500;
  color: #333;
}

.location {
  flex: 1;
  text-align: center;
  color: #666;
}

.time {
  flex: 1;
  text-align: right;
  color: #999;
}

/* 定义滚动动画 */
@keyframes scroll-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}

/* 响应式设计：调整在小屏幕上的样式 */
@media (max-width: 768px) {
  .scrolling-table {
    top: 30%; /* 调整位置 */
    left: 5%;
    width: 90%;
    height: 120px; /* 调整高度 */
    font-size: 14px;
  }

  .scroll-item {
    padding: 8px 10px;
  }

  .message {
    flex: 2;
  }

  .location, .time {
    flex: 1;
  }
}

/* 可选：在鼠标悬停时暂停滚动 */
.scrolling-table:hover .scrolling-content {
  animation-play-state: paused;
}
</style>
