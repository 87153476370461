<script setup>

</script>

<template>
  <div class="top-background">
    <!--标题-->
    <div class="top-title">
      <img src="../../pic/贷款融资/FInancingTitle.png" class="top-title-father">
      <div class="top-title-son">
        <img src="../../pic/贷款融资/持牌机构@2x.png" class="top-title-son-text">
        <img src="../../pic/贷款融资/直线%2022@2x.png" class="top-title-son-straight">
        <img src="../../pic/贷款融资/直线%2022@2x.png" class="top-title-son-straight">
        <img src="../../pic/贷款融资/高效放款@2x.png" class="top-title-son-text">
        <img src="../../pic/贷款融资/直线%2022@2x.png" class="top-title-son-straight">
        <img src="../../pic/贷款融资/放心无忧@2x.png" class="top-title-son-text">
      </div>
    </div>

  </div>
</template>

<style scoped>
.top-background {
  background-image: url("@/pic/贷款融资/组 90@2x.png");
  width: 100%;
  height: 395px;
  background-size: cover;
  background-position: top ;
  background-repeat: repeat;
  overflow: hidden;
  position: relative;
  top: 24px;
  margin: 0;
}


.top-title{
  position: absolute;
  left: 154px;
  top: 168px;
}
.top-title-father{
  width: 384px;
  height: 67px;
}
.top-title-son{
  position: absolute;
  top:70px;

}
.top-title-son-text{
  width: 64px;
  height: 22px;
  margin-right: 19px;
}
.top-title-son-straight{
  height: 20px;
  margin-right: 19px;
}
</style>