<template>
  <!--   第二层-->
  <div class="home-two">
    <img src="../../pic/首页/蓝三角.png" alt="" style="width: 66px;height: 53px;position: absolute; top: 87px; left: 183px;">
    <span class="text-big" style="position: absolute;top: 112px;left: 231px">融资产品</span>
    <span class="text-small" style="position: absolute;top: 119.5px;left: 412px">为您推荐当前热门产品</span>
    <span class="text-small" style="position: absolute;top: 119.5px;left: 1582px;">更多产品</span>
    <img src="../../pic/首页/右箭头.png" style="position: absolute;top: 111px;left: 1669px;width: 20px;height: 20px">
    <div class="product-container">
      <div v-for="(product, index) in products" :key="index" class="product" :style="{ left: `${index * 382}px` }">
        <img :src="images[index]" alt="" style="position:absolute;top: 34px;left: 70px;width: 66px;height: auto">
        <span class="product-text" style="position: absolute;top: 52px;left: 147px">{{ product.name }}</span>
        <span class="product-text1" style="position: absolute;top: 73px;left: 147px">最高额度：<span class="product-text2">{{ product.limit }}</span></span>
        <div class="product-mes" style="position: absolute;top: 139px;left: 31px">
          <span class="product-mes-text" style="position:absolute;top: 17px;left: 57px">{{ product.rate }}</span>
          <span class="product-mes-text" style="position:absolute;top: 57px;left: 57px">{{ product.repayment }}</span>
          <span class="product-mes-text" style="position:absolute;top: 98px;left: 57px">{{ product.period }}</span>
        </div>
        <span class="product-text3" style="position: absolute;top: 311px;left: 140px">{{ product.feature }}</span>
        <span class="product-text4" style="position: absolute;top: 352px;left: 98px">{{ product.advantage }}</span>
        <button class="product-button" style="position: absolute;top: 393px;left: 59px"><span class="product-button-text" style="position: absolute;top: 18px;left: 85px;">立即申请</span></button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref,onMounted} from 'vue';
import axios from '@/utils/axios';

const list = ref([]);
const products = ref([]);
const images = ref([]);



function get_products() {
  axios.post('/product/list',{}).then(res => {
    list.value = res.data.data.lst;
    images.value = list.value.map(item => 'https://jianxj.com/bank/' + item.logo);
    products.value = list.value.slice(0, 4).map(item => {
      return {
        name: item.name,
        limit: item.max_amount + '万',
        rate: '年化利率' + item.min_interest_rate + '%-' + item.max_interest_rate + '%',
        repayment: '还款' + item.repayment,
        period: '周期' + 4 + '期', //接口待定
        feature: '低利率', //接口待定
        advantage: item.advantage
      }
    });
  });
}

onMounted(() => {
  get_products();
});


</script>

<style scoped>
.home-two{
  width: 100%;
  height: 735px;
  position: relative;

  left: 0;
  background-color: #F1F3FB;
  text-align: center;
}
.product-container {
  width: 100vh;
  height: 475px;
  position: relative;
  top: 170px;
  left: 185px;
}
.product{
  width: 356px;
  height: 475px;
  border-radius: 7px;
  background-color: #FFFFFF;
  position: absolute;
}
.product-text{
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 21px;
  color: #292929;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.product-text1{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #777777;
  font-size: 18px;
  text-align: left;
  vertical-align: top;
  letter-spacing: 0px;
  line-height: 18px;
}
.product-text2{
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #EA0A25;
  font-size: 18px;
  text-align: left;
  vertical-align: top;
  letter-spacing: 0px;
  line-height: 18px;
}
.product-text3{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: rgba(57, 96, 255, 1);
  font-size: 26px;
  text-align: left;
  letter-spacing: 0px;
  line-height: 26px;
}
.product-text4{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: rgba(41, 41, 41, 1);
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  line-height: 26px;
}
.product-button{
  width: 240px;
  height: 61px;
  border: white;
  border-radius: 7px;
  background: linear-gradient(90deg, rgba(41, 83, 255, 1) 0%, rgba(35, 196, 253, 1) 100%);
}
.product-button-text{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  line-height: 26px;
}
.product-mes {
  width: 295px;
  height: 139px;
  border-radius: 13px;
  background-color: rgba(40, 97, 255, 0.05);
}
.product-mes-text{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #000000;
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  line-height: 18px;
}
.text-big{
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 36px;
  color: #292929;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.text-small{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 21px;
  color: #747474;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
</style>
