<template>
  <div class="box">
    <el-input
        v-model="phoneNumber"
        prefix-icon="Iphone"
        placeholder="请输入手机号"
        style="font-size:16px; width: 311px; height: 46px;">
    </el-input>

    <el-input
        v-model="verificationCode"
        placeholder="请输入验证码"
        style="font-size: 16px; width: 311px; height: 46px;">
      <template #prefix>
        <img src="@/pic/登录/组%2062@2x.png" alt="icon" style="width: 14px; height: 16.43px;"/>
      </template>
      <template #suffix>
        <span
            @click="sendVerificationCode"
            style="color: blue; font-size: 16px; cursor: pointer;">
          发送验证码
        </span>
      </template>
    </el-input>

    <div class="choose">
      <el-checkbox v-model="keepAccount" label="keep" />
      <span style="font-family: 'PingFang SC', sans-serif; font-weight: 400; font-size: 15px; line-height: 15px; color: #2953FF;">
        立即注册>>
      </span>
    </div>

    <el-button
        color="#2953FF"
        type="primary"
        style="width: 311px; height: 40px; font-size: 16px"
        @click="authorize">
      立即授权
    </el-button>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axiosInstance from '@/utils/axios.js'; // 根据实际路径调整
import { ElMessage } from 'element-plus';

const phoneNumber = ref('');
const verificationCode = ref('');
const keepAccount = ref(false);

const sendVerificationCode = async () => {
  const phoneRegex = /^1[3-9]\d{9}$/;
  if (!phoneNumber.value) {
    ElMessage.error('请输入手机号');
    return;
  }
  if (!phoneRegex.test(phoneNumber.value)) {
    ElMessage.error('请输入有效的手机号');
    return;
  }

  try {
    const response = await axiosInstance.post('/user/verify_code', {
      phone: phoneNumber.value,
    });

    if (response.code === 200) {
      ElMessage.success('验证码已发送');
      // 这里可以添加倒计时逻辑
    } else {
      ElMessage.error('发送失败，请重试');
    }
  } catch (error) {
    console.error(error);
    ElMessage.error('请求出错，请稍后再试');
  }
};

const authorize = async () => {
  if (!phoneNumber.value || !verificationCode.value) {
    ElMessage.error('请填写手机号和验证码');
    return;
  }

  try {
    const response = await axiosInstance.post('/user/login', {
      phone: phoneNumber.value,
      code: verificationCode.value,
    });

    if (response.code === 200 && response.data) {
      ElMessage.success('授权成功');
      const token = response.data.access_token;
      localStorage.setItem('token', token);
      // 处理成功逻辑，例如跳转页面
    } else if (response.msg =='验证码错误或已过期'){
      ElMessage.error('验证码错误或已过期');
    } else if (response.msg == '请先关注公众号并在公众号登陆'){
      ElMessage.error('请先关注公众号并在公众号登陆');
    } else {
      ElMessage.error('授权失败，请重试');
    }
  } catch (error) {
    console.error(error);
    ElMessage.error('请求出错，请稍后再试');
  }
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: center;
  flex-direction: column;  /* 设置元素垂直排列 */
  gap: 20px;
}

.choose {
  font-size: 16px;
  width: 311px;
  height: 46px;
  display: flex; /* 启用 flex 布局 */
  justify-content: flex-start;
  align-items: center; /* 垂直居中子元素 */
  gap: 148px;
}

.keeppw ::v-deep .el-checkbox__label {
  color: black !important;
}

.keeppw.is-checked ::v-deep .el-checkbox__label {
  color: black !important;
}

.keeppw:active ::v-deep .el-checkbox__label {
  color: black !important;
}
</style>
