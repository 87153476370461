<template>
  <div class="black-bar"></div> <!-- 黑条 -->
  <div :class="['contect', pageClass]">
    <div class="a">
      <div class="logo">
        <div class="logo-circle"></div>
        <div class="logo-text">简享家</div>
      </div>
      <div class="location">
        <img src="../../pic/贷款融资/FinancingWay.png" style="width: 12.96px;height: 17.28px;">
        <img src="../../pic/贷款融资/FInancingLocation.png" style="width: 67px;height: 20px;">
        <img src="../../pic/贷款融资/多边形%201@2x.png" style="width: 12px;height: 8px;top: 2px;">
      </div>
      <div class="nav-links">
        <router-link to="/" class="mynav">首页</router-link>
        <router-link to="/financing" class="mynav">贷款融资</router-link>
        <router-link to="/financingplan" class="mynav">融资方案</router-link>
        <router-link to="/creditinstruments" class="mynav">信贷工具</router-link>
        <router-link to="/product" class="mynav">产品方案</router-link>
        <router-link to="/login" class="mynav">用户登录</router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
.black-bar {
  width: 100%;
  height: 33px;
  background-color: #333333;
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 0 7px 0 0;
  transition: top 0.3s ease-out; /* 添加平滑过渡效果 */
  z-index: 1;
}

.contect {
  width: 100%;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center; /* 水平居中整个a容器 */
  position: fixed;  /* 固定在视口顶部 */
  top: 33px;  /* 给 .contect 设置上边距，让它位于黑条下方 */
  left: 0;
  z-index: 2;
  transition: top 0.5s ease-out, background-color 0.5s ease-out, box-shadow 0.5s ease-out; /* 添加背景颜色和阴影的过渡效果 */
}
.contect-white{
  width: 100%;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center; /* 水平居中整个a容器 */
  position: fixed;  /* 固定在视口顶部 */
  top: 33px;  /* 给 .contect 设置上边距，让它位于黑条下方 */
  left: 0;
  background:#ffffff;
  box-Shadow :0px 4px 12px 2px rgba(0, 0, 0, 0.3);
  z-index: 2;
  transition: top 0.5s ease-out, background-color 0.5s ease-out, box-shadow 0.5s ease-out; /* 添加背景颜色和阴影的过渡效果 */
}


.a {
  display: flex;
  align-items: center;
  width: 1800px;
  height: 80px;
  position: relative;
  user-select: none;
}

.logo {
  display: flex;
  align-items: center;
  position: absolute;
  left: 139px;
}

.logo-circle {
  margin-right: 4px;
  width: 36px;
  height: 36px;
  background: #2953FF;
  border-radius: 50%;
}

.logo-text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 34px;
  color: #000000;
  text-align: left;
}

.location {
  display: flex;
  align-items: center;
  position: absolute;
  left: 324px;
}

.nav-links {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* 导航链接水平方向对齐方式 */
  position: absolute;
  left: 911px;
}

.mynav {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 21px;
  color: #292929;
  text-align: left;
  margin-right: 54px;
  text-decoration: none;
}
</style>

<script>
export default {
  data() {
    return {
      pageClass: 'contect'  // 默认类名
    };
  },
  mounted() {
    // 通过路由的当前路径来决定不同页面的样式
    const path = this.$route.path;
    if(path == '/'){
      this.pageClass = 'contect'
    } else if(path == '/financing'){
      this.pageClass = 'contect'
    } else if(path == '/financingplan'){
      this.pageClass = 'contect-white'
    } else if(path == '/creditinstruments'){
      this.pageClass = 'contect'
    }else if(path == '/product'){
      this.pageClass = 'contect-white'
    }else if(path == '/login'){
      this.pageClass = 'contect'
    }

    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollY = window.scrollY;
      if (this.pageClass === 'contect' || this.pageClass === 'contect-white') {
        // 处理滚动位置，始终执行
        if (scrollY >= 30) {
          document.querySelector('.contect').style.top = '0px';
        } else {
          document.querySelector('.contect').style.top = '33px';
        }
      }

      // 只有在页面是 contect 时，才更改背景和阴影
      if (this.pageClass === 'contect') {
        if (scrollY >= 30) {
          document.querySelector('.contect').style.background = '#ffffff';
          document.querySelector('.contect').style.boxShadow = '0px 4px 12px 2px rgba(0, 0, 0, 0.3)';
        } else {
          document.querySelector('.contect').style.background = 'None';
          document.querySelector('.contect').style.boxShadow = 'None';
        }
      }
    },
  }

}
</script>

