<script>
import { ElInput } from 'element-plus';
import 'element-plus/theme-chalk/el-input.css';

export default {
  name: 'MyComponent',
  components: {
    ElInput
  }
};
</script>

<template>
  <div class="box">
    <el-input prefix-icon="Iphone" placeholder="请输入手机号" style="font-size:16px;width: 311px; height: 46px;"></el-input>
    <el-input prefix-icon="Picture" placeholder="请输入图形验证码" style="font-size:16px;width: 311px; height: 46px;"></el-input>

    <div class="choose">
      <el-checkbox label="记住账号" value="keep"  style=""/>
      <span style="font-family: 'PingFang SC', sans-serif;font-weight: 400;font-size: 15px;line-height:15px;color: #2953FF;">忘记密码</span>
    </div>
    <el-button color="#2953FF" type="primary" style="width: 311px; height: 40px;;font-size: 16px">立即授权</el-button>
  </div>
</template>

<style scoped>
.box{
  display: flex;
  justify-content: center;
  flex-direction: column;  /* 设置元素垂直排列 */
  gap: 20px;
}
.choose{
  font-size:16px;
  width: 311px;
  height: 46px;
  display: flex; /* 启用flex布局 */
  justify-content: flex-start;
  align-items: center; /* 垂直居中子元素 */
  gap: 168px;
}
.keeppw ::v-deep .el-checkbox__label {
  color: black !important;
}

.keeppw.is-checked ::v-deep .el-checkbox__label {
  color: black !important;
}

.keeppw:active ::v-deep .el-checkbox__label {
  color: black !important;
}

</style>
