<template>
  <div class="container">
    <NavBar />

    <div class="main">
      <FinancingFirst/>
      <FinancingTwo/>
    </div>

    <FooterBar />
  </div>
</template>

<script setup>

import NavBar from "@/components/通用/NavBar.vue"
import FooterBar from "@/components/通用/FooterBar.vue";
import FinancingFirst from "@/components/融资贷款/Financing-first.vue";
import FinancingTwo from "@/components/融资贷款/Financing-two.vue";
// 地区选择

</script>
<script>
export default {
  name: 'FinancingComponent',
};
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
}

.main {
  flex-shrink: 0; /* 不允许 main 压缩 */
  flex-grow: 1;
  width: 100%;
  margin: 0;
}
</style>