<template>
  <div class="login-first">
    <img src="@/pic/登录/组%20101@2x.png" alt="" class="loginimg">
    <div class="login">
      <div class="login-options">
        <!-- 快捷登录选项 -->
        <div
            :class="['login-option', { selected: loginType === 'quick' }]"
            @click="loginType = 'quick'"
        >
          快捷登录
        </div>
        <!-- 密码登录选项 -->
        <div
            :class="['login-option', { selected: loginType === 'password' }]"
            @click="loginType = 'password'"
        >
          密码登录
        </div>
      </div>

      <!-- 根据loginType显示不同的登录表单 -->
      <div v-if="loginType === 'quick'">
        <LoginForm type="quick"/>
      </div>

      <div v-if="loginType === 'password'">
        <PasswordLoginForm type="password"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import LoginForm from "@/components/登录/login-loginform.vue";
import PasswordLoginForm from "@/components/登录/login-password_loginform.vue";
// 记录当前登录类型，默认为快速登录
const loginType = ref('quick');
</script>

<style scoped>
.login-first {
  width: 100%;
  height: 782px;
  position: relative;
  left: 0;
  background-image: url("@/pic/登录/b0c24259c57d2e0dce23dd570eb87367c3a29046f0956-LcyION@2x.png");
  background-size: 100% 782px; /* 强制背景图覆盖整个容器 */
  background-repeat: no-repeat;
}

.loginimg {
  position: absolute;
  top: 150px;
  left: 227px;
  width: 595px;
  height: 595px;
}

.login {
  position: absolute;
  top: 27%;
  left: 65.63%;
  width: 21.38%;
  height: 59.11%;
  background-color: #FFFFFF;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column; /* 设置元素垂直排列 */
  gap: 50px;
}

.login-options {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.login-option {
  font-size: 18px;
  cursor: pointer;
  padding-bottom: 5px;
}

.login-option.selected {
  font-weight: bold;
  color: #2953FF;
  border-bottom: 3px solid #2953FF;
}
</style>

