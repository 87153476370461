<script setup>
import { ref } from 'vue';
const selectedIndex = ref(0);
</script>

<template>
  <div class="second">
    <div class="title">

    </div>
    <div id="app">
      <div class="container1">
        <!-- 左边按钮 -->
        <div class="left-buttons">
          <button @click="selectedIndex = 0">按钮 1</button>
          <button @click="selectedIndex = 1">按钮 2</button>
          <button @click="selectedIndex = 2">按钮 3</button>
          <button @click="selectedIndex = 3">按钮 4</button>
          <button @click="selectedIndex = 4">按钮 5</button>
          <button @click="selectedIndex = 5">按钮 6</button>
        </div>

        <!-- 右边内容 -->
        <div class="right-content">
          <div v-if="selectedIndex === 0" style="background-color: black">这是按钮 1 的内容</div>
          <div v-if="selectedIndex === 1" style="background-color: blue">这是按钮 2 的内容</div>
          <div v-if="selectedIndex === 2" style="background-color: blueviolet">这是按钮 3 的内容</div>
          <div v-if="selectedIndex === 3">这是按钮 4 的内容</div>
          <div v-if="selectedIndex === 4">这是按钮 5 的内容</div>
          <div v-if="selectedIndex === 5">这是按钮 6 的内容</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.second{
  width: 100%;
  height: 1048px;
  position: relative;
  left: 0px;
}

.container1{
  display: flex;
}

.left-buttons {
  width: 200px;
  padding: 10px;
  border-right: 1px solid #ddd;
}

.left-buttons button {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

.left-buttons button:hover {
  background-color: #e0e0e0;
}

.right-content {
  padding: 20px;
  flex: 1;
}

.right-content div {
  display: none;
}

.right-content div[v-if] {
  display: block;
}
</style>