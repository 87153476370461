<script>
import { ElInput } from 'element-plus';
import 'element-plus/theme-chalk/el-input.css';

export default {
  name: 'MyComponent',
  components: {
    ElInput
  }
};
</script>

<template>
  <div class="box">
    <el-input prefix-icon="Iphone" placeholder="输入企业名称" style="font-size:16px;width: 311px; height: 46px;"></el-input>
    <el-input prefix-icon="Picture" placeholder="输入企业纳税识别号" style="font-size:16px;width: 311px; height: 46px;"></el-input>
    <el-input prefix-icon="Picture" placeholder="企业所在地域" style="font-size:16px;width: 311px; height: 46px;"></el-input>
    <el-input prefix-icon="Picture" placeholder="输入图形验证码" style="font-size:16px;width: 311px; height: 46px;"></el-input>
    <el-button color="#2953FF" type="primary" style="width: 311px; height: 40px;;font-size: 16px">立即授权</el-button>
  </div>
</template>

<style scoped>
.box{
  display: flex;
  justify-content: center;
  flex-direction: column;  /* 设置元素垂直排列 */
  gap: 22px;
}
.keeppw ::v-deep .el-checkbox__label {
  color: black !important;
}

.keeppw.is-checked ::v-deep .el-checkbox__label {
  color: black !important;
}

.keeppw:active ::v-deep .el-checkbox__label {
  color: black !important;
}

</style>
